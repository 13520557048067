<template>
  <Teleport to="body">
    <div class="modal date-popup__root">
      <div class="modal__body ">
        <button
          class="modal__close"
          @click="onClosePopup"
        >
          <img
            src="~/assets/img/svg/common/close-black.svg"
            alt="close icon"
          >
        </button>
        <div class="date-popup__title"> Укажите удобное время и дату для звонка</div>
        <div class="date-popup__form">
          <div class="date-popup__datepicker">
            <label>Дата</label>
            <VueDatePicker
              v-if="dateAndTime"
              v-model="dateAndTime.date"
              model-type="dd.MM.yyyy"
              auto-apply
              :format="format"
              :close-on-auto-apply="true"
              locale="ru"
              :min-date="new Date()"
              :enable-time-picker="false"
              placeholder="Сегодня"
              :clearable="false"
            >
              <template #input-icon>
                <img
                  class="date-picker__icon"
                  src="~/assets/img/svg/date-picker/date-picker.svg"
                  alt="icon"
                >
              </template>
            </VueDatePicker>
          </div>

          <div class="date-popup__select">
            <label>Время </label>
            <CustomSelect
              :select-list="listTime"
              :selected-sort="dateAndTime?.time"
              label-name="label"
              active-value="Не важно"
              @emitOptions="changeTime"
            />
          </div>

          <div class="date-popup__buttons">
            <div
              class="btn outlined --shadow"
              @click="onCancel"
            >
              Отменить
            </div>
            <div
              class="btn primary --shadow"
              @click="onClosePopup"
            >
              Выбрать
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal__overlay"
        @click="onClosePopup"
      ></div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker';
import CustomSelect from '~/components/UI/CustomSelect.vue';

export type DateAndTimePopupType = {
	date: Date | string;
	time: {
		label: string;
		value: string;
	}
}
const props = defineProps<{
	currentTime:DateAndTimePopupType | null;
}>();

const emits = defineEmits<{
	onClose: [dateAndTime: DateAndTimePopupType | null]
}>();

const format = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

const dateAndTime = ref<DateAndTimePopupType | null>({
  date: format(new Date()),
  time: {
    value: '',
    label: ''
  }
});

const listTime = computed(() => {
  if (dateAndTime.value && !dateAndTime.value.date) {
    return [];
  }

  const listTime = [];
  let startHours = 10;

  const currentDate = new Date().toLocaleDateString('ru', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  const selectedDate = new Date(dateAndTime?.value?.date || '').toLocaleDateString('ru', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  if (currentDate === selectedDate) {
    startHours = new Date().getHours() + 1;
  }

  for (let hour = startHours; hour < 24; hour++) {
    listTime.push({
      label: `${hour} : 00`, value: `${hour}:00`
    });
  }
  return listTime;
});

const changeTime = (opt: { label: string; value: string }) => {
  if (dateAndTime.value) {
    dateAndTime.value.time = opt;
  }
};

const onCancel = () => {
  dateAndTime.value = null;
  onClosePopup();
};

const onClosePopup = () => {
  emits('onClose', dateAndTime.value);
};

onMounted(() => {
  if (props.currentTime) {
    dateAndTime.value = props.currentTime;
  }
});

</script>

<style lang="scss">
@import "@/assets/scss/media";

.date-popup__root {
	display: flex;
	justify-content: center;
	align-items: center;

	.modal__body {
		max-width: 320px;
		padding: 30px 20px;
		overflow: initial;
	}

	.modal__close {
		width: 14px;
		height: 14px;
		top: 10px;
		right: 12px;

		& > img {
			filter: invert(17%) sepia(23%) saturate(3908%) hue-rotate(239deg) brightness(94%) contrast(107%);
		}
	}
}

.date-popup__title {
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 124%;
	color: var(--primary);
	text-align: center;
	margin-bottom: 40px;
}

.date-popup__select {
	margin-bottom: 24px;

	.select-element {
		height: 48px;
		background: var(--white);
		border: 1px solid var(--primary-15);
		font-size: 16px;

		& > span {
			margin-left: 0;
		}

		&:hover {
			border-color: var(--primary);
		}
	}

	& .select-dropdown {
		border-radius: 10px;
		margin-top: 2px;
		border: 1px solid var(--primary-15);
		background-color: white;
		max-height: 170px;
		overflow-y: auto;

		& > div {
			background-color: var(--white);
			font-size: 16px;
			color: var(--primary);

			&:hover {
				background-color: var(--primary-10);
			}
		}

		& > div:first-child {
			border-radius: 10px 10px 0 0;
		}
	}
}

.date-popup__form {
	& label {
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 110%;
		color: var(--primary);
		margin-bottom: 8px;
		display: block;
	}
}

.date-popup__datepicker {
	margin-bottom: 24px;
}

.date-popup__buttons {
	display: flex;
	justify-content: space-between;
}

.modal__overlay {
	opacity: 1;
	backdrop-filter: blur(8px);
}
</style>
